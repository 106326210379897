import { CSSProperties } from 'react'

interface LabelProps {
  text: string
  id?: string
  style?: CSSProperties
  className?: string
}

export function Label({ text, id, style, className }: LabelProps) {
  return (
    <label className={className} style={style} htmlFor={id}>
      {text}
    </label>
  )
}
