import React from 'react'
import { PuffLoader } from 'react-spinners'

export default function MinimalLoading({ style }: { style?: React.CSSProperties }) {
  return (
    <div
      style={{
        ...style,
        // width: 'max-content',
        // height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
      }}
    >
      <PuffLoader color={document.documentElement.style.getPropertyValue('--accent-clr')} />
    </div>
  )
}
