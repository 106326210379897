import { useEffect, useRef, useState } from 'react'
import { IoIosArrowDown } from 'react-icons/io'

interface DropdownProps<T extends { [x: string]: any }> {
  data: T[]
  current?: T
  displayProp: keyof T
  onChange?: (newItem: T) => any
  closeOnExit?: boolean
  style?: React.CSSProperties
}

export function Dropdown<T extends { [x: string]: any }>({ current: c, data, displayProp, onChange, style, closeOnExit = true }: DropdownProps<T>) {
  const [open, setOpen] = useState(false)
  const [current, setCurrent] = useState(c || data[0] || 'no options')
  const elementRef = useRef<any>(null)

  useEffect(() => {
    onChange?.(current)
  }, [current])

  useEffect(() => {
    c && setCurrent(c)
  }, [c])

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      // @ts-ignore
      if (elementRef.current && !elementRef.current.contains(event.target)) {
        setOpen(false)
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <div className="dropdown-wrapper" style={style}>
      <div ref={elementRef} className={`dropdown ${open ? 'opened' : 'closed'}`}>
        <div className="current" onClick={() => setOpen(!open)}>
          <p>{current[displayProp]}</p>
          {data.length > 1 && <IoIosArrowDown className="icon" />}
        </div>
        <div style={{ overflowY: 'auto', width: '100%' }}>
          {data
            .filter(el => el[displayProp] != current[displayProp])
            .map((item, i) => {
              return (
                <div
                  className="dropdown-sub"
                  key={i}
                  onClick={() => {
                    setCurrent(item)
                    setOpen(false)
                  }}
                >
                  {item[displayProp]}
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}
