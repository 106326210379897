import React, { CSSProperties } from 'react'

interface TextFieldProps {
  id: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => any
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void
  style?: CSSProperties
  defaultValue?: any
  type?: 'text' | 'email'
  value?: string
  ref?: React.Ref<HTMLInputElement>
}

export function TextField({ id, onChange, onPaste, style, defaultValue, type = 'text', value, ref }: TextFieldProps) {
  return (
    <div className="input-container">
      <input
        value={value}
        defaultValue={defaultValue}
        style={{ ...style }}
        onChange={e => onChange?.(e)}
        onPaste={e => onPaste?.(e)}
        id={id}
        type={type}
        ref={ref}
      />
    </div>
  )
}
