import { CSSProperties } from 'react'

interface LoginWithProps {
  text: string
  onClick?: () => any
  color?: string
  img?: string
  style?: CSSProperties
  disabled?: boolean
  children?: React.ReactNode
  selected?: boolean
}

export function LoginWith({ text, onClick, color, img, style, disabled, children, selected }: LoginWithProps) {
  return (
    <button
      disabled={disabled}
      className="loginwith"
      // @ts-ignore
      style={{ ...style, '--accent-clr': color, 'border': 'none', 'outline': selected ? '2px solid var(--accent-clr)' : 'none' }}
      onClick={onClick}
      type="button"
    >
      {img && <img src={img} alt="" />}
      {text}
      {children}
    </button>
  )
}
