import React, { useEffect, useState } from 'react'
import { Card } from '..'
import { Pie, Bar } from 'react-chartjs-2'
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ArcElement,
  RadialLinearScale,
} from 'chart.js'
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, BarElement, Title, Tooltip, Legend, Filler, ArcElement, RadialLinearScale)
import { useTheme } from '../../../contexts'
import { Bucket } from '../../../types/analytics'
import { useScreen } from '../../../hooks/useScreen'
import { useAnalytics } from '.'
import { BiRefresh } from 'react-icons/bi'

export function LabelDistributionAvarage() {
  const { width } = useScreen()
  const { getColors } = useTheme()
  const { data, refreshAnalytics } = useAnalytics()
  const [timeframe, setTimeframe] = useState(30)
  const [labelDistribution, setLabelDistribution] = useState<any>({})

  useEffect(() => {
    if (!data) return
    setLabelDistribution(getLabelDistribution(data?.buckets, timeframe))
  }, [data, timeframe])

  function getValue(name: string) {
    return getComputedStyle(document.documentElement).getPropertyValue(name)
  }

  function getLabelDistribution(buckets: Bucket[], timeframe = 30) {
    const counter = {
      'Polissen - Tekst.ai': 0,
      'Betalingen - Tekst.ai': 0,
      'Maatschappij informatie - Tekst.ai': 0,
      'Leads - Tekst.ai': 0,
      'Schade - Tekst.ai': 0,
    }
    if (!buckets) return counter
    const length = timeframe > buckets.length ? buckets.length : buckets.length - timeframe
    for (let i = buckets.length - 1; i > length; i--) {
      for (let j = 1; j < buckets[i].length; j++) {
        if (counter.hasOwnProperty(buckets[i][j])) {
          // @ts-ignore
          counter[buckets[i][j]] = counter[buckets[i][j]] + 1
        }
      }
    }
    return counter
  }

  const chartData = {
    labels: Object.keys(labelDistribution),
    datasets: [
      {
        data: Object.values(labelDistribution),
        backgroundColor: [...getColors(5)],
        hoverOffset: 15,
      },
    ],
  }

  const options = {
    plugins: {
      legend: {
        display: width < 768 ? true : false,
        labels: {
          color: getValue('--secondary-text-clr'),
          font: {
            size: 12,
            weight: '500',
            family: 'Cake',
          },
        },
        position: width < 450 ? ('bottom' as 'bottom') : ('left' as 'left'),
      },
      tooltip: {
        enabled: true,
        intersect: true,
        backgroundColor: getValue('--secondary-bg-clr'),
        bodyColor: getValue('--primary-text-clr'),
        bodyFont: {
          size: 14,
          weight: '500',
          family: 'Cake',
        },
        cornerRadius: 7,
        boxPadding: 8,
        caretPadding: 12,
        caretSize: 7,
        displayColors: false,
        usePointStyle: true,
        padding: 8,
      },
    },
    elements: {
      arc: {
        borderColor: getValue('--primary-bg-clr'),
        borderWidth: 10,
        hoverBorderWidth: 10,
        hoverBorderColor: getValue('--primary-bg-clr'),
      },
    },
    maintainAspectRatio: true,
  }

  return (
    <Card style={{ gap: '5px' }} title="Label Distribution">
      <div className="refresh-analytics" onClick={e => refreshAnalytics()}>
        <BiRefresh />
      </div>
      <div className="timeselectors">
        <TimeframeLabel
          onClick={() => {
            setTimeframe(30)
          }}
          active={timeframe == 30}
        >
          30 days
        </TimeframeLabel>
        <TimeframeLabel
          onClick={() => {
            setTimeframe(14)
          }}
          active={timeframe == 14}
        >
          14 days
        </TimeframeLabel>
        <TimeframeLabel
          onClick={() => {
            setTimeframe(7)
          }}
          active={timeframe == 7}
        >
          7 days
        </TimeframeLabel>
        <TimeframeLabel
          onClick={() => {
            setTimeframe(1.1)
          }}
          active={timeframe == 1.1}
        >
          1 day
        </TimeframeLabel>
      </div>
      <Pie options={options} data={chartData} />
    </Card>
  )
}

interface TimeFrameProps {
  children: React.ReactNode
  onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => any
  active?: boolean
}

export function TimeframeLabel({ children, onClick, active }: TimeFrameProps) {
  return (
    <div onClick={e => onClick?.(e)} className={`time ${active && 'active'}`}>
      {children}
    </div>
  )
}

export function LabelDistributionStacked() {
  const { data, refreshAnalytics } = useAnalytics()
  const { getColors } = useTheme()
  const [type, setType] = useState<'total' | 'percentage'>('total')
  const [timeframe, setTimeframe] = useState(30)
  const [dataset, setDataset] = useState([])

  function getValue(name: string) {
    return getComputedStyle(document.documentElement).getPropertyValue(name)
  }

  const folders = ['Polissen - Tekst.ai', 'Betalingen - Tekst.ai', 'Maatschappij informatie - Tekst.ai', 'Leads - Tekst.ai', 'Schade - Tekst.ai']

  const chartData = {
    labels: [...data.buckets]
      .reverse()
      .filter((el: any, i: number) => i < timeframe)
      .reverse()
      .map((bucket: Bucket) => bucket[0]),
    datasets: folders.map((folder, i) => {
      return {
        label: folder,
        backgroundColor: getColors(folders.length)[i],
        data: [...data.buckets]
          .reverse()
          .filter((el: any, i: number) => i < timeframe)
          .reverse()
          .map((bucket: Bucket) => {
            if (type == 'total') {
              return bucket.filter(f => f == folder).length
            } else {
              return (bucket.filter(f => f == folder).length / (bucket.length - 1)) * 100
            }
          }),
      }
    }),
  }

  const options = {
    plugins: {
      tooltip: {
        enabled: true,
        intersect: true,
        backgroundColor: getValue('--secondary-bg-clr'),
        bodyColor: getValue('--primary-text-clr'),
        bodyFont: {
          size: 14,
          weight: '500',
          family: 'Cake',
        },
        cornerRadius: 7,
        boxPadding: 8,
        caretPadding: 12,
        caretSize: 7,
        displayColors: false,
        usePointStyle: true,
        padding: 8,
      },
      legend: {
        display: false,
        labels: {
          color: getValue('--secondary-text-clr'),
          font: {
            size: 12,
            weight: '500',
            family: 'Cake',
          },
        },
        // position: width < 450 ? ('bottom' as 'bottom') : ('left' as 'left'),
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        beginAtZero: true,
      },
    },
  }

  return (
    <Card style={{ maxWidth: 'auto', gap: '5px', flex: '1 1 0' }} title="Labels Over Time">
      <div className="refresh-analytics" onClick={e => refreshAnalytics()}>
        <BiRefresh />
      </div>
      <div className="timeselectors">
        <TimeframeLabel
          onClick={() => {
            setType('total')
          }}
          active={type == 'total'}
        >
          total
        </TimeframeLabel>
        <TimeframeLabel
          onClick={() => {
            setType('percentage')
          }}
          active={type == 'percentage'}
        >
          percentage
        </TimeframeLabel>
        <div style={{ width: '1px', height: '100%', backgroundColor: 'var(--secondary-bg-clr)', margin: '0 4px' }}></div>
        <TimeframeLabel
          onClick={() => {
            setTimeframe(30)
          }}
          active={timeframe == 30}
        >
          30 days
        </TimeframeLabel>
        <TimeframeLabel
          onClick={() => {
            setTimeframe(14)
          }}
          active={timeframe == 14}
        >
          14 days
        </TimeframeLabel>
        <TimeframeLabel
          onClick={() => {
            setTimeframe(7)
          }}
          active={timeframe == 7}
        >
          7 days
        </TimeframeLabel>
        <TimeframeLabel
          onClick={() => {
            setTimeframe(1)
          }}
          active={timeframe == 1}
        >
          1 day
        </TimeframeLabel>
      </div>
      <Bar options={options} data={chartData} />
    </Card>
  )
}
