import { CSSProperties } from 'react'
import { SyncLoader } from 'react-spinners'

interface ButtonProps {
  text: string | React.ReactNode
  onClick?: () => any
  type?: 'button' | 'submit' | 'reset'
  variant?: 'primary' | 'outline'
  disabled?: boolean
  loading?: boolean
  style?: CSSProperties
}

export function Button({ text, onClick, type = 'button', variant = 'primary', disabled, loading, style }: ButtonProps) {
  return (
    <button style={style} disabled={disabled} className={`${variant} ${loading && 'loading'}`} onClick={onClick} type={type || 'button'}>
      {loading && (
        <SyncLoader
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
          }}
          size="5px"
          color="var(--primary-text-clr)"
        />
      )}
      {text}
    </button>
  )
}
