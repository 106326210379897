import React, { CSSProperties } from 'react'

interface FormProps {
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => any
  children?: React.ReactNode
  style?: CSSProperties
}

export function Form({ onSubmit, children, style }: FormProps) {
  return (
    <form className="default" onSubmit={onSubmit} style={style}>
      {children}
    </form>
  )
}
