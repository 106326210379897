import React, { CSSProperties, useState } from 'react'
import { BiInfoCircle } from 'react-icons/bi'

export function Tooltip({
  text,
  placement,
  style,
  width,
}: {
  width?: string
  text: string
  placement?: 'top' | 'left' | 'right' | 'bottom'
  style?: CSSProperties
}) {
  const [showTooltip, setShowTooltip] = useState(false)

  const handleMouseEnter = () => {
    setShowTooltip(true)
  }

  const handleMouseLeave = () => {
    setShowTooltip(false)
  }

  const tooltipStyle: CSSProperties = {
    position: 'absolute',
    zIndex: '1',
    backgroundColor: 'var(--secondary-bg-clr)',
    boxShadow: '0px 0px 42px -13px rgba(0,0,0,0.5)',
    color: 'var(--secondary-text-clr)',
    padding: '5px 10px',
    borderRadius: '5px',
    fontSize: '10px',
    fontWeight: '700',
    width: width || '100px',
    whiteSpace: 'normal',
  }

  let tooltipPosition: CSSProperties

  switch (placement) {
    case 'top':
      tooltipPosition = { bottom: '150%', left: '50%', transform: 'translateX(-50%)' }
      break
    case 'left':
      tooltipPosition = { top: '50%', right: '150%', transform: 'translateY(-50%)' }
      break
    case 'right':
      tooltipPosition = { top: '50%', left: '150%', transform: 'translateY(-50%)' }
      break
    case 'bottom':
    default:
      tooltipPosition = { top: '150%', left: '50%', transform: 'translateX(-50%)' }
      break
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        cursor: 'pointer',
        width: '15px',
        height: '15px',
      }}
    >
      <span onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <BiInfoCircle color="var(--secondary-text-clr)" size={12} width={20} strokeWidth={0.2} style={{ ...style }} />
      </span>
      {showTooltip && <div style={{ ...tooltipStyle, ...tooltipPosition }}>{text}</div>}
    </div>
  )
}

export default Tooltip
