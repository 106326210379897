interface MainProps {
  children: React.ReactNode
  style?: React.CSSProperties
  contentStyle?: React.CSSProperties
  className?: string
  header?: React.ReactNode
}

export function Main({ children, style, className, header, contentStyle }: MainProps) {
  if (header) {
    return (
      <div className={`main-container with-header ${className}`}>
        <div className="header">{header}</div>
        <div style={contentStyle} className="main-container">
          {children}
        </div>
      </div>
    )
  }

  return (
    <div style={style} className={`main-container ${className}`}>
      {children}
    </div>
  )
}
