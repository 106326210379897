interface ErrorProps {
  condition: any
  children: React.ReactNode
}

export function Error({ children, condition }: ErrorProps) {
  if (condition) {
    return <p className="input-error">{children}</p>
  }
  return <></>
}
