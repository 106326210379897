import { useRouter } from 'next/router'
import MinimalLoading from '../helpers/MinimalLoading'
import { MiniTitle } from '../page'
import Tooltip from '../../utils/ToolTip'
import { PulseLoader } from 'react-spinners'

interface SimpleDataProps {
  title: string
  data?: string | number | React.ReactNode
  children?: React.ReactNode
  event?: Function | string
  style?: React.CSSProperties
  className?: string
  loading?: boolean
  tooltip?: { content: string; width?: string; height?: string; position?: 'top' | 'bottom' | 'left' | 'right' }
  refetching?: boolean
}

export function SimpleData({ title, data, children, event, style, className, loading, refetching, tooltip }: SimpleDataProps) {
  const router = useRouter()

  function handleClick() {
    typeof event == 'string' ? router.push(event) : event?.()
  }

  return (
    <div
      style={event ? { ...style, cursor: 'pointer' } : style}
      className={`stat simple ${className} ${loading ? 'loading' : ''}`}
      onClick={handleClick}
    >
      {loading && <MinimalLoading style={{ position: 'absolute', left: '10px', top: '50%', transform: 'translate(-50%, -50%)', scale: '.5' }} />}
      <div style={{ opacity: 1, position: 'relative', display: 'flex', flexDirection: 'column', gap: '5px', height: '100%' }}>
        {!loading && refetching && (
          <PulseLoader
            style={{ position: 'absolute', right: '0px', top: '-10px', opacity: '.7' }}
            size={4}
            color={document.documentElement.style.getPropertyValue('--primary-text-clr')}
            speedMultiplier={0.4}
          />
        )}
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row', marginBottom: '5px', gap: '5px' }}>
          <MiniTitle type="small">{title}</MiniTitle>
          {tooltip && <Tooltip text={tooltip.content} placement={tooltip.position} width={tooltip.width}></Tooltip>}
        </div>
        {!loading && data != undefined && <h2>{data}</h2>}
        {!loading && children}
      </div>
    </div>
  )
}
