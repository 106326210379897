interface InputProps {
  children: React.ReactNode
  styles?: React.CSSProperties
}

export function Input({ children, styles }: InputProps) {
  return (
    <div style={styles} className="input-wrapper default">
      {children}
    </div>
  )
}
