import { CSSProperties } from 'react'

interface TextAreaProps {
  id: string
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => any
  style?: CSSProperties
}

export function TextArea({ id, onChange, style }: TextAreaProps) {
  return (
    <div className="input-container">
      <textarea onChange={e => onChange?.(e)} id={id} style={{ resize: 'none', ...style }} />
    </div>
  )
}
