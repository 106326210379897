interface SelectProps {
  id: string
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => any
  children?: React.ReactNode
}

export function Select({ id, onChange, children }: SelectProps) {
  return (
    <div className="input-container">
      <select onChange={e => onChange?.(e)} id={id}>
        {children}
      </select>
    </div>
  )
}
