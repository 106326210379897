import { CSSProperties } from 'react'
import { PulseLoader } from 'react-spinners'
import Tooltip from '../../utils/ToolTip'
import MinimalLoading from '../helpers/MinimalLoading'
import { MiniTitle } from '../page'

interface CardProps {
  title: string
  children?: React.ReactNode
  style?: CSSProperties
  loading?: boolean
  refetching?: boolean
  tooltip?: { content: string; width?: string; height?: string; position?: 'top' | 'bottom' | 'left' | 'right' }
}

export function Card({ title, children, style, loading, refetching, tooltip }: CardProps) {
  return (
    <div style={{ ...style }} className="card">
      {loading && <MinimalLoading style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }} />}
      <div
        style={{
          opacity: 1,
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          height: '100%',
          width: '100%',
        }}
      >
        {!loading && refetching && (
          <PulseLoader
            style={{ position: 'absolute', right: '0px', top: '-10px', opacity: '.7' }}
            size={4}
            color={document.documentElement.style.getPropertyValue('--primary-text-clr')}
            speedMultiplier={0.4}
          />
        )}
        <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          <MiniTitle type="small">{title}</MiniTitle>
          {tooltip && <Tooltip text={tooltip.content} placement={tooltip.position} width={tooltip.width}></Tooltip>}
        </div>
        {!loading && children}
      </div>
    </div>
  )
}
